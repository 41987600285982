<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">{{$t('i18nn_f77f473d27f48bdc')}}</h3>
		</div> -->

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
					<!-- <li> -->
					<!-- <el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_556c0672dcecba13')}}</el-button> -->
					<!-- </li> -->
					<!-- </ul> -->

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->

		<div class="filterCon" style="">
			<!-- <div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>{{i18nFormatter(item.value)}}</span>
					</el-menu-item>
				</el-menu>
			</div> -->
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_556c0672dcecba13')}}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">

					<!-- <li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()">
						</el-button>
					</li> -->
				</ul>
			</div>
		</div>

		<!-- <div class="wh-filter-con">
			<div class="">
			  <span>{{$t('Storage.packing_list.status')}}</span>
				<el-radio-group v-model="filterData.status" size="medium" @change="serPageData()">
				  <el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">
						{{item.label}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->

		<!-- <div class="wh-filter-con">
			
			
			<div class="wh-filter-item">
				<span>{{ $t('Storage.packing_list.status') }}</span>
				<el-select filterable clearable v-model="filterData.status" :placeholder="$t('FormMsg.Please_select')" size="small" style="width: 100px;" @change="serPageData()">
					<el-option v-for="item in selectOption.statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con">
			<el-button type="success" @click="openDioalog(null, $t('i18nn_556c0672dcecba13'))" size="small" icon="el-icon-plus">{{$t('i18nn_556c0672dcecba13')}}</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->



				<el-table-column prop="proxyStatusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type=""
							v-if="'0' == scope.row.proxyStatus">{{ $Utils.i18nKeyText(scope.row,'proxyStatusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'10' == scope.row.proxyStatus">{{ $Utils.i18nKeyText(scope.row,'proxyStatusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'20' == scope.row.proxyStatus">{{ $Utils.i18nKeyText(scope.row,'proxyStatusName') }}</el-tag>
						<el-tag type="danger" v-else-if="'90'==scope.row.proxyStatus">{{scope.row.proxyStatusName}}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'proxyStatusName') }}</el-tag>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="mobile" :label="$t('i18nn_b00576fed9b3a1f2')">
					<template slot-scope="scope">
						{{ scope.row.mobile }}
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="cusWhName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->
				<el-table-column prop="proxyCusWhName" :label="'代理用户'"></el-table-column>
				<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->
				<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" width="200">
					<template slot-scope="scope">
						<div>
							<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{scope.row.createTime}}</div>
							<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{scope.row.updateTime}}</div>
							<!-- <div><span>{{$t('i18nn_889b3ef0590d9f54')}}</span>：{{scope.row.startTime}}</div> -->

						</div>
					</template>
				</el-table-column>


				<el-table-column :label="$t('Storage.tableColumn.operation')" width="300px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>
							<div style="margin-bottom: 10px;">
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">
									删除
								</el-button>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false"  :title="$t('i18nn_556c0672dcecba13')" :visible.sync="dialogFormVisible" custom-class="myFullDialog3">
			<el-form ref="form" :rules="formRules" :model="form" label-width="150px" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
				
				<el-form-item :label="$t('i18nn_b00576fed9b3a1f2')" prop="">
					<el-input type="text" v-model="form.mobile" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input>
				</el-form-item>
				
			
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog> -->



		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="0">
			<div style=""><workOrderDet :id="packId"></workOrderDet></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogShowDet = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->


	</div>
</template>
<script>
	import {
		agentUser_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	export default {

		components: {
			// SelAgentUser
		},
		data() {
			return {

				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				// dialogFormMsg: '',

				loading: false,

				// form: {
				// 	 "mobile":"",//this.$t('i18nn_b00576fed9b3a1f2')
				// },

				// formRules: {
				// 	mobile: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// },
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_work_order_type: [],
					statusList: agentUser_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					status: '',
				}
			};
		},

		//创建时
		created() {},
		//编译挂载前
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},

			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm('确定删除吗？', this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {

					});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				this.postData(this.$urlConfig.WhProxyUserDel + '/' + row.userId, {
					ids: row.id
				});
			},

			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						this.postData(this.$urlConfig.agentUserAdd, formData);
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				this.$nextTick(() => {
					if (this.$refs[formName]) {
						this.$refs[formName].resetFields();
					} else {
						console.log('this.$refs[formName]', this.$refs[formName]);
					}
				});
			},

			//提交信息
			postData(url, formData, type) {
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.getPageData();

						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}

					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

			handleSelectStatus(key, keyPath) {
				this.filterData.status = key;
				this.initData();
			},
			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.agentUserPageList, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						proxyStatus: this.filterData.status ? this.filterData.status : null,
						// packingNo: this.filterData.packingNo ? this.filterData.packingNo : null
					})
					.then(({
						data
					}) => {
						console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {
	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>